import './time-style.css'
let dateFormat = require('dateformat')
let cityOff = require('./cityOffsets.json')

const yourSlide = document.querySelector('.yourSlide')
const milanSlide = document.querySelector('.milanSlide')
const dcSlide = document.querySelector('.dcSlide')
const stgoSlide = document.querySelector('.stgoSlide')
const califSlide = document.querySelector('.califSlide')

const onePlus = document.querySelector('.onePlus')
const oneLess = document.querySelector('.oneLess')
const goNow = document.querySelector('.goNow')

let generalOffset = cityOff.generalOffset
let milannOffset = cityOff.milan
let stgoOffset = cityOff.stgo
let dcOffset = cityOff.dc
let califOffset = cityOff.california
let userShift = 0
let shiftLimit = 8

console.log(cityOff)

var now = new Date()
var nowOffset = now.getTimezoneOffset()/60
var nowHH = (dateFormat(now, 'HH') * 1 )
var zeroTime = (nowHH + nowOffset)
var yourTime = (nowHH + generalOffset)%24
var milanTime = (zeroTime + milannOffset + generalOffset)%24
var stgoTime = (zeroTime + stgoOffset + generalOffset)%24
var dcTime = (zeroTime + dcOffset + generalOffset)%24
var califTime = (zeroTime + califOffset + generalOffset)%24


const nowTime = () => {
    now = new Date()
    nowOffset = now.getTimezoneOffset()/60
    nowHH = (dateFormat(now, 'HH') * 1 )
    zeroTime = (nowHH + nowOffset)
    yourTime = (nowHH + generalOffset)%24
    milanTime = (zeroTime + milannOffset + generalOffset)%24
    stgoTime = (zeroTime + stgoOffset + generalOffset)%24
    dcTime = (zeroTime + dcOffset + generalOffset)%24
    califTime = (zeroTime + califOffset + generalOffset)%24

    yourSlide.setAttribute("style", `transform: translateX(${yourTime* -50 }px)`)
    milanSlide.setAttribute("style", `transform: translateX(${(milanTime * -50) }px)`)
    dcSlide.setAttribute("style", `transform: translateX(${(dcTime*-50) }px)`)
    stgoSlide.setAttribute("style", `transform: translateX(${(stgoTime*-50 ) }px)`)
    califSlide.setAttribute("style", `transform: translateX(${(califTime*-50 ) }px)`)

    onePlus.setAttribute("style", "pointer-events: auto;")
    oneLess.setAttribute("style", "pointer-events: auto;")

    userShift = 0
}
setTimeout(nowTime, 50);

const shiftTimeUp = () => {
    if(userShift < shiftLimit-1){
        onePlus.setAttribute("style", "opacity:1")
        userShift = userShift + 1
        oneLess.setAttribute("style", "opacity:1")
    }else{
        onePlus.setAttribute("style", "opacity:.2;background-color:#999999;pointer-events: none;")
        userShift = shiftLimit
    }
    yourSlide.setAttribute("style", `transform: translateX(${(yourTime + userShift)* -50}px)`)
    milanSlide.setAttribute("style", `transform: translateX(${(milanTime + userShift) * -50 }px)`)
    dcSlide.setAttribute("style", `transform: translateX(${(dcTime + userShift) * -50 }px)`)
    stgoSlide.setAttribute("style", `transform: translateX(${(stgoTime + userShift) * -50 }px)`)
    califSlide.setAttribute("style", `transform: translateX(${(califTime + userShift) * -50 }px)`)
}
const shiftTimeDown = () => {
    if(userShift > -shiftLimit+1){
        oneLess.setAttribute("style", "opacity:1")
        userShift = userShift - 1
        onePlus.setAttribute("style", "opacity:1")
    }else{
        oneLess.setAttribute("style", "opacity:.2;background-color:#999999;pointer-events: none;")
        userShift = -shiftLimit
    }
    yourSlide.setAttribute("style", `transform: translateX(${(yourTime + userShift)* -50}px)`)
    milanSlide.setAttribute("style", `transform: translateX(${(milanTime + userShift) * -50 }px)`)
    dcSlide.setAttribute("style", `transform: translateX(${(dcTime + userShift) * -50 }px)`)
    stgoSlide.setAttribute("style", `transform: translateX(${(stgoTime + userShift) * -50 }px)`)
    califSlide.setAttribute("style", `transform: translateX(${(califTime + userShift) * -50 }px)`)
}

onePlus.addEventListener("click", shiftTimeUp )
oneLess.addEventListener("click", shiftTimeDown )
goNow.addEventListener("click", nowTime )


